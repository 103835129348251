html,
body, #root {
  height: 100%;
  width: 100%;
}
body
{

font-family:Cerebri Sans,sans-serif;font-size:.9375rem;font-weight:400;line-height:1.5;margin:0;text-align:left;color:#003a82;background-color:#f9fbfd;
background-color: #f5f5f5;
}
textarea {
  white-space: pre-line;
}
.container{
  padding-top: 4.5rem; 
}
body {
  font-family: sans-serif;
}

input:not([type='checkbox']) {
  border: 1px solid #999;
  padding: 0.5rem;
}
input[type='checkbox'] {
  position: static;
  width: 30px;
}

.no-suggestions {
  color: #999;
  padding: 0.5rem;
}

.suggestions {
  position: absolute;
  z-index: 100;
  background: #f5f5f5;
  border: 1px solid #999;
  border-top-width: 0;
  list-style: none;
  margin-top: 0;
  max-height: 143px;
  overflow-y: auto;
  padding-left: 0;
  width: calc(250px + 1rem);
}
.suggestions li {
  padding: 0.5rem;
}

.suggestion-active,
.suggestions li:hover {
  background-color: #003a82!important;
  color: #f5f5f5;
  cursor: pointer;
  font-weight: 700;
}

.suggestions li:not(:last-of-type) {
  border-bottom: 1px solid #999;
}


.check-button{
  color: #c5cedb!important;
  padding: .45rem 1rem!important;
  margin-left: .1875rem!important;
  margin-right: .1875rem!important;
  font-size: .9375rem;
  line-height: 1.5;
  display: block;
  float: left;
  border-radius: .375rem!important;
  background-color: #4d5b6e!important;
}
.check-button:hover{
  background-color: #003a82!important;
  color: #ffffff!important;
  cursor: pointer;
}
.check-button-selected{
  color: #ffffff!important;
  padding: .45rem 1rem!important;
  margin-left: .1875rem!important;
  margin-right: .1875rem!important;
  font-size: .9375rem;
  line-height: 1.5;
  display: block;
  float: left;
  border-radius: .375rem!important;
  background-color: #024eb9!important;
}

.tag{
  color: #c5cedb!important;
  padding: .75rem!important;
  margin: .1875rem!important;
  float: left!important;
  border-radius: .375rem!important;
  background-color: #3a567a!important;
}
.tag-selected{
  padding: .75rem!important;
  margin: .1875rem!important;
  float: left!important;
  border-radius: .375rem!important;
  background-color: #2c7be5!important;
  color: #ffffff!important;
}
.tag:hover{
  background-color: #003a82!important;
  color: #ffffff!important;
  cursor: pointer;
}
.tag-selected:hover{
  background-color: #3889f5!important;
  color: #ffffff!important;
  cursor: pointer;
}
.process-list{
  width: 30rem;
}
.process-item{
  display: block;
  word-wrap: break-word;
  padding: .5rem!important;
  margin: 0rem .1875rem!important;
  width: 30rem;
  float: left!important;
  border-bottom: solid 0.1rem #383e46;
}

.process-item:first-child{
  border-top-left-radius: .375rem!important;
  border-top-right-radius: .375rem!important;
}
.process-item:last-child{
  border-bottom-left-radius: .375rem!important;
  border-bottom-right-radius: .375rem!important;
  border-bottom: none;
}


.modal-item{
  display: block;
  word-wrap: break-word;
  padding: .5rem!important;
  margin: 0rem .1875rem!important;
  width:100%;
  float: left!important;
  border-bottom: solid 0.1rem #383e46;
}
.modal-item:first-child{
  border-top-left-radius: .375rem!important;
  border-top-right-radius: .375rem!important;
}
.modal-item:last-child{
  border-bottom-left-radius: .375rem!important;
  border-bottom-right-radius: .375rem!important;
  border-bottom: none;
}
.form-checkbox{
  border: 1px solid #999;
}
.hover{
  color: #c5cedb!important;
}
.hover:hover{
  color: #ffffff!important;
  cursor: pointer;
}
.hover-dark{
  color: #003a82!important;
}
.hover-dark:hover{
  color: #000b18!important;
  cursor: pointer;
}
@media print {
  * {
     -webkit-print-color-adjust: exact !important;
  }
}
.table-curved {
  border-collapse: separate;
  width:100%;
  border-spacing:0px;
}

.table-curved1 tr {
  position: relative;
  border-radius: 26px;
  border-collapse: separate;
  border-spacing: 2px;
  background-position-x: 10px;
  padding:10px 10px;
  transition:background .1s;
}
.table-curved tr:hover {
  background:#eee;
}


.table-curved tr:last-child td:first-child {
  border-radius: 0 0 0 6px;
}
.table-curved tr:last-child td:last-child {
  border-radius: 0 0 6px 0;
}
.table-curved  td, .table-curved th {
  padding: .9375rem;
  vertical-align: top;
  border-top: 1px solid #edf2f9;
}
#google-map{
  width: 100%;
  height: 100%;
}
.flex-grow {
  flex: 1 0 auto;
}

.issue-Title {
  padding: .9375rem;
  vertical-align: top;
  border-top: 1px solid #edf2f9;
}
.shadow {
  box-shadow: 0 .15rem 1.75rem 0 rgba(58,59,69,.15)!important;
}

.border-left-success {
  border-left: .25rem solid #1cc88a!important;
}
.react-autosuggest__container {
  position: relative;
}


.react-autosuggest__suggestions-container {
  display: none;
}

.react-autosuggest__suggestions-container--open {
  display: block;
  position: absolute;
  top: 40px;
  width: 280px;
  border: 1px solid #aaa;
  background-color: #fff;
  font-family: Helvetica, sans-serif;
  font-weight: 300;
  font-size: 16px;
  border-radius: 4px;
  z-index: 2;
}

.react-autosuggest__suggestions-list {
  margin: 0;
  padding: 0;
  list-style-type: none;
}

.react-autosuggest__suggestion {
  cursor: pointer;
  padding: 10px 20px;
}

.react-autosuggest__suggestion--highlighted {
  background-color: #ddd;
}

.link {
  text-decoration: none;
  color: #2c7be5;
  background-color: transparent;
  -webkit-text-decoration-skip: objects;
}
.link:hover {
  text-decoration: none;
  color: #1657af;
  cursor: pointer;
}
.link-header {
  text-decoration: none;
  color: hsl(0, 0%, 100%);
  background-color: transparent;
  -webkit-text-decoration-skip: objects;
}
.link-header:hover {
  text-decoration: none;
  color: hsl(214, 39%, 55%);
  cursor: pointer;
}
.cursor-pointer{
  cursor: pointer;
}
.text-black{
  color: #000000!important;
}
.dashboard-actioning{
font-size: 0.8rem;
margin-right: 0.5rem;
display: inline-block;
}
.nest-img-print {
  -webkit-filter: invert(1);
  filter: invert(1);
}
@media all {
  .page-break {
    display: none;
  }
}
 
@media print {
  html, body {
    height: initial !important;
    overflow: initial !important;
    -webkit-print-color-adjust: exact;
    background: #ffffff !important;
  }
}
@media print {
  * {
    background: #ffffff !important;
  }
}
@media print {
  .page-break {
    margin-top: 1rem;
    display: block;
    page-break-before: always;
  }
}
 
@page {
  size: auto;
  margin: 20mm;
}

.table-bordered, .table-bordered td, .table-bordered th {
  border: 1px solid #000000;
  padding: 0.5rem;
}

.border-0
{
border:0!important
}

.border-top-0
{
border-top:0!important
}

.border-right-0
{
border-right:0!important
}

.border-bottom-0
{
border-bottom:0!important
}

.border-left-0
{
border-left:0!important
}
.bg-black{
  background-color: #000000;
}
.bg-grey{
  background-color: #444444;
}
.bg-danger-light{
  background-color:  #e9d4d4!important;
}
.border-blk{
  border-color: #000000 !important;
}
.text-decoration-line-through {
  text-decoration: line-through !important;
}
@media print {
  thead th{
    -webkit-print-color-adjust:exact;
  }
  .table-bordered, .table-bordered td, .table-bordered th {
    border-color: #000000  !important;
    border: 1px solid #000000;
    padding: 0.3rem;
  }
  .no-borders, .no-borders td, .no-borders th {
    border: none !important;
    padding: 0.2rem;
  }
  td.border-0, th.border-0
  {
  border:0!important
  }
  
  td.border-top-0, th.border-top-0
  {
  border-top:0!important
  }
  
  td.border-right-0, th.border-right-0
  {
  border-right:0!important
  }
  
  td.border-bottom-0, th.border-bottom-0
  {
  border-bottom:0!important
  }
  
  td.border-left-0, th.border-left-0
  {
  border-left:0!important
  }
}
@media print {
  h1{
    font-size: 2.5rem;
  }
  body{
    font-size: 20px;
  }
  td.bg-black{
    background-color: #000000!important;
  }
  td.bg-grey{
    background-color: #444444!important;
  }
}